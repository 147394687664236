<template>
  <div class="main-content">
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width80 textAlignRight"
                >员工账号 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.username"
                  placeholder="请输入"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width80 textAlignRight"
                >操作菜单 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.menu"
                  placeholder="请输入"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width70 textAlignRight"
                >操作类型 :
              </span>
              <div class="width300">
                <el-select
                  class="width300"
                  v-model="searchData.operateType"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in OOperationLog"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width80 textAlignRight"
                >绑定时间 :
              </span>
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :clearable="true"
                >
                  <!-- @change="handleSearch" -->
                </el-date-picker>
              </div>
            </div>
            <el-button
              size="medium"
              type="primary"
              @click="handleSearch"
              style="margin-bottom: 10px"
              class="marginLeft10"
              >搜索</el-button
            >
            <el-button
              size="medium"
              type="info"
              @click="handleReset"
              class="marginLeft10"
              style="margin-bottom: 10px"
              >重置</el-button
            >
          </div>
        </div>
      </template>
      <template slot="operateType" slot-scope="{ row }">
        {{
          row.operateType == EOperationLog.add
            ? MOperationLog[row.operateType]
            : row.operateType == EOperationLog.modify
            ? MOperationLog[row.operateType]
            : row.operateType == EOperationLog.del
            ? MOperationLog[row.operateType]
            : row.operateType == EOperationLog.export
            ? MOperationLog[row.operateType]
            : row.operateType == EOperationLog.Import
            ? MOperationLog[row.operateType]
            : ""
        }}
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" @click="openForm('create', row.id)"
          >详情</el-button
        >
      </template>
    </avue-crud>
    <UserForm ref="formRef" @success="handleSearch" />
  </div>
</template>

<script>
import { logList, logView } from "@/api/system/log";
import dayjs from "dayjs";
import {
  EOperationLog,
  MOperationLog,
  OOperationLog,
} from "@/enums/beCurrent/EBeCurrent";
import UserForm from "./UserForm.vue";

export default {
  name: "index",
  components: {
    UserForm,
  },
  data() {
    return {
      EOperationLog: EOperationLog,
      MOperationLog: MOperationLog,
      OOperationLog: OOperationLog,
      searchData: {
        menu: "",
        createTime: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      showLoadingForm: false,
      dialogVisible: false,
      option: {
        rowKey: "index",
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: true,
        column: [
          {
            label: "操作地址",
            prop: "ip",
          },
          {
            label: "操作菜单",
            prop: "operateMenu",
          },
          {
            label: "操作类型",
            prop: "operateType",
          },
          {
            label: "员工账号",
            prop: "username",
          },
          {
            label: "操作时间",
            prop: "createTime",
          },
        ],
      },

      dialogVisible: false,
      // form: {
      //   id: "",
      //   // sort: " ",
      //   title: "",
      //   details: "",
      // },
    };
  },
  created() {},
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        ...this.searchData,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      delete searchData.createTime;
      // console.log(searchData,'this.searchDatathis.searchData');
      logList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        menu: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },

    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },
    // 发放徽章
    openForm(type, id) {
      this.$refs.formRef.open(type, id);
    },
  },
};
</script>

<style scoped></style>
