<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :title="dialogTitle"
    width="40%"
    :before-close="handleClose"
  >
    <div class="box">
      <div class="item_left">操作地址：</div>
      <div class="item_right">{{ formData.ip }}</div>
    </div>
    <div class="box">
      <div class="item_left">操作菜单：</div>
      <div class="item_right">{{ formData.menu }}</div>
    </div>
    <div class="box">
      <div class="item_left">操作方法：</div>
      <div class="item_right">{{ formData.method }}</div>
    </div>
    <div class="box">
      <div class="item_left">操作类型：</div>
      <div class="item_right">
        {{ MOperationLog[formData.operateType] || "" }}
      </div>
    </div>
    <div class="box">
      <div class="item_left">操作方式：</div>
      <div class="item_right">{{ formData.requestMethod }}</div>
    </div>
    <div class="box">
      <div class="item_left">请求参数：</div>
      <div class="item_right">{{ formData.requestParams }}</div>
    </div>

    <div class="box">
      <div class="item_left">返回参数：</div>
      <div class="item_right">{{ formData.responseParams }}</div>
    </div>
    <div class="box">
      <div class="item_left">请求地址：</div>
      <div class="item_right">{{ formData.url }}</div>
    </div>
    <template #footer>
      <!-- <el-button :disabled="formLoading" type="primary" @click="submitForm"
        >确 定</el-button
      > -->
      <el-button @click="dialogVisible = false">关 闭</el-button>
    </template>
  </el-dialog>
</template>
<script>
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { Message } from "element-ui";
import { logView } from "@/api/system/log";
import {
  EOperationLog,
  MOperationLog,
  OOperationLog,
} from "@/enums/beCurrent/EBeCurrent";

export default {
  name: "index",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      EOperationLog: EOperationLog,
      MOperationLog: MOperationLog,
      OOperationLog: OOperationLog,
      dialogVisible: false, // 弹窗的是否展示
      dialogTitle: "", // 弹窗的标题
      formLoading: false, // 表单的加载中：1）修改时的数据加载；2）提交的按钮禁用
      formType: "", // 表单的类型：create - 新增；update - 修改
      formData: {},
      formRules: {
        username: [
          { required: true, message: "门店账号不能为空", trigger: "blur" },
        ],
      },
      classifyList: [],
    };
  },
  created() {},
  methods: {
    // const formRef = ref() // 表单 Ref
    /** 打开弹窗 */
    async open(type, id) {
      this.dialogVisible = true;
      this.dialogTitle = "详情";
      this.formType = type;
      this.resetForm();
      // 修改时，设置数据
      if (id) {
        this.formLoading = true;
        try {
          const res = await logView(id);
          if (res.code === 200) {
            this.formData = res.data;
          }
        } finally {
          this.formLoading = false;
        }
      }
    },
    async submitForm() {
      // 校验表单
      if (!this.formRef) return;
      const valid = await this.formRef.value.validate();
      if (!valid) return;
      // 提交请求
      formLoading.value = true;
      try {
        const data = formData.value;
        if (formType.value === "create") {
          await UserApi.createUser({ ...data, type: 1 });
          Message.success("新增成功");
        } else {
          await UserApi.updateUser(data);
          Message.success("修改成功");
        }
        dialogVisible.value = false;
        // 发送操作成功的事件
        this.$emit("success");
      } finally {
        formLoading.value = false;
      }
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.formData.image = [...e];
      } else {
        this.formData.image = [...this.form.image, e];
      }
    },
    // 重置
    resetForm() {},
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
.box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.item_left {
  width: 80px;
}
.item_right {
  width: 80%;
}
</style>
